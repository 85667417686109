/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql, Link} from "gatsby"

import Header from "./header"
import "../scss/style.scss"

const Layout = ({children}) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<>
			<Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
			<div className="bg-grid-raster-default-center force">
				<div className="bg-grid-raster-large-center force">
					<main style={{minHeight: "60vh"}}>{children}</main>
				</div>
			</div>
			<div className="block-padding-vertical box-shadow">
				<div className="edge">
					<div className="grid medium-grid-4">
						<nav className="span-2 footer-menu trim">
							<h3>Features</h3>
							<div className="grid grid-2">
								<ul className="footer-menu__list">
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/typography/">Typography</Link>
									</li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/space/">Space</Link></li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/grid/">Grid</Link></li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/flex/">Flex</Link></li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/edge/">Edge</Link></li>
								</ul>
								<ul className="footer-menu__list">
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/viewports/">Viewports</Link>
									</li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/utilities/">Utilities</Link></li>
									<li className="footer-menu__item"><Link className="footer-menu__link" to="/features/"><strong>All features</strong></Link></li>
								</ul>
							</div>
						</nav>
						<nav className="footer-menu trim">
							<h3>Github Repositories</h3>
							<ul className="footer-menu__list">
								<li className="footer-menu__item"><Link className="footer-menu__link"
								                                        to="https://github.com/HarwinBorger/SpaceFramework"
								                                        target="_blank">SpaceFramework</Link></li>
								<li className="footer-menu__item"><Link className="footer-menu__link"
								                                        to="https://github.com/HarwinBorger/SpaceFramework-CLI"
								                                        target="_blank">CLI</Link></li>
								<li className="footer-menu__item"><Link className="footer-menu__link" to="https://github.com/HarwinBorger/SpaceFont"
								                                        target="_blank">Fonts</Link></li>
								<li className="footer-menu__item"><Link className="footer-menu__link"
								                                        to="https://github.com/HarwinBorger/SpaceTemplates"
								                                        target="_blank">Templates</Link></li>
								<li className="footer-menu__item">SpaceFramework.dev</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>

			<div className="bg-black text-white block-padding-vertical">
				<div className="edge">
					<div className="grid-columns">
						<div className="medium-span-4">
							© {new Date().getFullYear()}, Spaceframework.dev
						</div>
						<div className="medium-span-4 trim text-align-center"><p><Link to="/external-links/">External links</Link></p></div>
						<div className="medium-span-4 text-align-right trim">
							<p>Build with <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">Gatsby</a> and hosted by <a
								href="https://www.netlify.com" target="_blank" rel="noreferrer">Netlify</a></p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
