import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Github from "../images/GitHub-Logo.svg"

const Header = ({siteTitle}) => (
	<header className={"menu-bar"}>
		<div className={"edge"}>
			<div className="grid-columns row-gap-0 align-items-center">
				<div className="span-9 large-span-3 flex align-items-center">
					<Link className={"logo"} to="/"><span className={"logo__text"}>{siteTitle}</span></Link>
				</div>
				<nav className={"span-12 large-span-7 order-3 large-order-2 main-menu small-only-breakout-edge"}>
					<ul className={"main-menu__list"}>
						<li className={"main-menu__item"}><Link className={"main-menu__link"} to={"/features/"}>Features</Link></li>
						<li className={"main-menu__item"}><Link className={"main-menu__link"} to={"/guide/"}>Guide</Link></li>
						<li className={"main-menu__item"}><a className={"main-menu__link"}  href={"https://docs.spaceframework.dev"}>Documentation</a></li>
						<li className={"main-menu__item"}><Link className={"main-menu__link"} to={"/ecosystem/"}>Ecosystem</Link></li>
						<li className={"main-menu__item"}><Link className={"main-menu__link"} to={"/cli/"}>CLI</Link></li>
					</ul>
				</nav>
				<nav className="span-3 order-2 large-order-3  large-span-2 flex align-items-center justify-content-right">
						<Link to={"https://github.com/HarwinBorger/SpaceFramework"} target={"_blank"}><img className={"display-block"} width={"64px"} src={Github} alt={"github"}/></Link>
				</nav>
			</div>
		</div>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
